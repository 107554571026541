import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AHRService, Case, NewsStory, CaseState, DossierFileDescriptor, CandidateFiles, ReviewStep, ActionType, TaskStatus, CaseFiles, CaseId } from '../../../domain';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser'
import { ConsoleService } from '../../../widgets/console.service';
import { TooltipService } from '../../../widgets/tooltip.service';


@Component({
	selector: 'academic-case-detail',
	templateUrl: './academic-case-detail.component.html',
	styleUrls: ['./academic-case-detail.component.scss']
})
export class AcademicCaseDetailComponent implements OnInit, OnChanges {
	@Input() case: Case = null;
	@Input() resultsVisible: boolean = false;
	CaseState = CaseState;
	news: NewsStory[] = null;
	dossierFiles = { };
	ShowCandidateReviewStage: boolean = false;
	CandidateReviewStep: ReviewStep = null;
    caseId:CaseId;
	candidateName: string;
	constructor(
		private svc: AHRService,
		private md: MatDialog,
		private readonly domSanitizer: DomSanitizer,
		private sb: MatSnackBar,
		private Console: ConsoleService,
		public tooltipSvc: TooltipService
		) { 
			
		}

	ngOnInit() {
		this.ShowCandidateReviewStage = this.candidateCanAccessReviewStage();
		this.candidateName = this.case.userName;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if('case' in changes){
			let c = <Case>changes['case'].currentValue;
			this.case = c;
			this.caseId = new CaseId(this.case.id,this.case.yearId);
			this.svc.getNewsStoriesForCurrentUser(c.yearId).subscribe(news => this.news = news);
			this.svc.getDossierFileDescriptorsForCurrentUser(c.yearId,c.id).subscribe(candidateFiles => {
				this.dossierFiles[c.yearId] = candidateFiles as CandidateFiles;
				if (candidateFiles.candidateFiles) {
					this.dossierFiles[c.yearId].candidateFiles = candidateFiles.candidateFiles;
				}
				if (candidateFiles.candidateCanReadFiles) {
					this.dossierFiles[c.yearId].candidateCanReadFiles = candidateFiles.candidateCanReadFiles;
				}
				this.dossierFiles[c.yearId].reviewerFiles = {};
				this.Console.log(this.dossierFiles);

				// if a committee name is included in candidateCanReadFiles then the candidate can see that file.
				this.dossierFiles[c.yearId].candidateFiles = 
					this.dossierFiles[c.yearId].candidateFiles
					.filter(file => 
						// Candidate files may have committee: "" or "Candidate" or "CANDIDATE"
						file.committeeName == "" 
						|| file.committeeName.toLowerCase() == "candidate" 
						|| this.dossierFiles[c.yearId].candidateCanReadFiles.includes(file.committeeName));
			});
			
		}
	}

	getInstructions(): string {
		return this.tooltipSvc.tooltip("AcademicProgramReview.Instructions");
	}
 	
	hasInstructions() {
		if (this.getInstructions().length > 0) {
			return true;
		} 
		return false;
	}
	
	download(file: DossierFileDescriptor){
		//this.svc.downloadDossierFile(this.case.yearId, file.id);
		this.sb.open('Downloading file...',null, {duration: 2000});
		let fileName = this.case.yearId > 2022? file.id : file.name;
		this.svc.dossierFileObjectUrl(this.case.yearId, fileName,this.case.id).subscribe(url => {
			//this.openUrlViaClick(url,'_blank');
			//this.openUrlInNewTab(url);
			//PdfviewerModalComponent.open(this.md, 'Case File', this.domSanitizer.bypassSecurityTrustResourceUrl(url))
		});
	}
	openUrlViaClick(url: any, target: string = ''){
		let anchor = window.document.createElement('a');
		anchor.href = url;
		anchor.target = target;
		document.body.appendChild(anchor);
		anchor.click();
		document.body.removeChild(anchor);
	}
	openUrlInNewTab(url: any){
		let win = window.open('/assets/loading.html');
		win.onload = () => {
			win.location.href = url;
		};
	}

	wfaSSO(){
		this.svc.viewCaseInWFA(this.case.id);
	}

	candidateCanAccessReviewStage(){
		let result = false;
		if(this.case.state == CaseState.InReview){
			this.case.reviewersByStepId.forEach((reviewers, key) => {
				reviewers.forEach((reviewer, index) => {
					if(reviewer.userId == this.case.userId) {
						this.case.reviewSteps.forEach(rs => {
							if(rs.id == key) {
								this.CandidateReviewStep = rs;
							}
						});
						result = true;
					}
				});
			});
		}
		return result;
	}

	showcandidateReviewStep(){
		if(this.CandidateReviewStep != null)
			return this.CandidateReviewStep.longName;
		return " is ready.";
	}

	isCaseWithCandidate() : string {
	 	
		if (this.case.yearId <= 2022)
			return "false";
		
		if (this.case.reviewStepMetadata.length === 0)
			return "pending";
		
		if (this.case.reviewStepMetadata?.some(p=>p.taskName.includes('Candidate') && p.status == TaskStatus.InProgress))
			return "true";

		return "false";
	}
}