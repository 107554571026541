<div class="card card-outline-info mb-3">	
	<div *ngIf="hasInstructions()">
		<span [innerHTML]="getInstructions()"></span>	
	</div>	
</div>

<div class="card">
	<ng-container [ngSwitch]="resultsVisible">
		<div class="card-block" *ngSwitchCase="false">
			These results are not yet available
		</div>

		<div class="card-block" *ngSwitchCase="true">
			<div class="row">
				<div class="col-12">
					<div class="mb-2 text-muted text-sm">
						<strong>Starting Position</strong>
					</div>
					<div>
						{{case.currentPosition | titleRankStepTerm}}
					</div>

					<div class="mt-4 mb-2 text-muted text-sm">
						<strong>Status</strong>
					</div>
					<div>
						<div [ngSwitch]="isCaseWithCandidate()">
							<div *ngSwitchCase="'pending'">
								<p>Pending case initiation</p>
							</div>
							<div *ngSwitchCase="'true'">
								<p>Ready to receive your review documents</p>
								<button class="btn btn-primary mt-1" (click)="wfaSSO()">
									<i class="fa fa-fw fa-sign-in"></i>
									Upload your review documents now
								</button>
							</div>
							<div *ngSwitchCase="'false'">
								<p>Documents received and case submitted</p>
							</div>
						</div>
					</div>
					<div *ngIf="ShowCandidateReviewStage">
						<p>Review Step: {{showcandidateReviewStep()}}</p>
						<button class="btn btn-primary mt-1" (click)="wfaSSO()">
							<i class="fa fa-fw fa-sign-in"></i>
							Upload your review documents now
						</button>
					</div>
					<div class="mt-4 mb-2 text-muted text-sm">
						<strong>Proposed Action</strong>
					</div>
					<div [ngSwitch]="!!case.proposedAction.value">
						<div *ngSwitchCase="true">
							<proposed-action [caseAdvancement]="case.asCaseAdvancement()"></proposed-action>
						</div>
						<div *ngSwitchCase="false" class="text-muted">
							None Yet
						</div>
					</div>

					<div class="mt-4 mb-2 text-muted text-sm">
						<strong>Proposed Position</strong>
					</div>
					<div readonly [ngSwitch]="!!case.proposedAction.value">
						<div *ngSwitchCase="true">
							{{case.proposedAction.value.endingPosition | titleRankStepTerm}}
						</div>
						<div *ngSwitchCase="false" class="text-muted">
							N/A
						</div>
					</div>

					<div *ngIf="case.state == CaseState.Complete">
						<div class="mt-4 mb-2 text-muted text-sm">
							<strong>Result</strong>
						</div>
						<div readonly [ngSwitch]="!!case.result.value">
							<div *ngSwitchCase="true">
								{{case.result.value}}
							</div>
							<div *ngSwitchCase="false" class="text-muted">
								N/A
							</div>
						</div>
					</div>

					<div  *ngIf="!!dossierFiles[case.yearId]">
						<div class="mt-4 mb-2 text-muted text-sm">
							<strong>Case Files</strong>
						</div>
						<div class="panel-content" style="padding-left: 0.5rem;">
							<case-files [files]="dossierFiles[case.yearId]" [caseId]="caseId"
							 *ngIf="!!dossierFiles[case.yearId]"
								[candidateFilesVisible]="true"
								[reviewerFilesVisible]="false"
								[candidateFiles] ="true"
								[candidateName]="candidateName"                            
							></case-files>
	
							<div *ngIf="!dossierFiles[case.yearId]">Loading...</div>
						</div>
					</div>
				</div>

				
			</div>
		</div>
	</ng-container>
</div>